import React from 'react';
import { Container, Row } from 'react-bootstrap';
import IndexPostContent from './IndexPostContent';
import './BlogIndex.css';
import CategoryWidget from './CategoryWidget';
import MetaTags from '../Seo/MetaTags';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const BlogIndex = ({ propsData: { pageContext }, results, setQuery }) => {
  const { currentPage, numPages, categories } = pageContext;
  const { t } = useTranslation('Blog');

  return (
    <>
      <MetaTags
        title={t('index.seo.title')}
        description={t('index.seo.description')}
      />

      {currentPage > 1 && (
        <Helmet>
          <meta name="yandex" content="noindex, follow" />
        </Helmet>
      )}

      <div
        className="news-section pt-12 pb-8 py-lg-23 bg-default-2"
        itemScope={true}
        itemType="https://schema.org/Blog"
      >
        <Container>
          <Row className="justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9 mt-9">
              <div className="section-title text-center mb-11 mb-lg-18">
                <h1 className="title gr-text-4 mb-6" itemProp="description">
                  {t('index.title')}
                </h1>
              </div>
            </div>
          </Row>

          <CategoryWidget categories={categories} setQuery={setQuery} />

          <IndexPostContent
            nodes={results}
            currentPage={currentPage}
            numPages={numPages}
            type="blog"
          />
        </Container>
      </div>
    </>
  );
};

export default BlogIndex;
