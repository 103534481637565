import React, { useContext, useEffect, useMemo, useState } from 'react';
import lunr from 'lunr';
import lunrStemmer from 'lunr-languages/lunr.stemmer.support';
import lunrRu from 'lunr-languages/lunr.ru';

import { graphql } from 'gatsby';
import BlogIndex from '../components/Blog/BlogIndex';
import GlobalContext from '../context/GlobalContext';
import useDebounce from '../hooks/useDebounce';

lunrStemmer(lunr);
lunrRu(lunr);

const BlogPage = (props) => {
  const [searchResults, setSearchResults] = useState([]);
  const gContext = useContext(GlobalContext);
  const { data, pageContext } = props;
  const { allImages } = data;
  const debouncedSearch = useDebounce(gContext.blogSearchQuery, 300);

  const searchIndex = useMemo(
    () => lunr.Index.load(JSON.parse(pageContext.searchIndex)),
    []
  );

  const searchStore = useMemo(
    () => JSON.parse(pageContext.searchStore),
    [pageContext.searchStore]
  );

  const searchImages = useMemo(
    () =>
      allImages.nodes.reduce((acc, node) => {
        acc[node.slug] =
          node.featuredImageRetina.length > 0
            ? node.featuredImageRetina
            : node.featuredImage;
        return acc;
      }, {}),
    []
  );

  useEffect(() => {
    if (debouncedSearch.length > 0) {
      const results = searchIndex.search(debouncedSearch);
      setSearchResults(results.map((result) => searchStore[result.ref]));
    } else {
      setSearchResults([]);
    }
  }, [searchStore, searchIndex, debouncedSearch]);

  let results;
  const postsPerPage = 12;

  if (!gContext.blogSearchQuery || !searchResults) {
    const { allCraftBlogBlogEntry } = data;
    const { nodes } = allCraftBlogBlogEntry;
    results = nodes;
    pageContext.numPages = pageContext.numPagesCommon;
  } else {
    pageContext.numPages = Math.ceil(searchResults.length / postsPerPage);

    let tmpResults = [];
    for (
      let i = (pageContext.currentPage - 1) * postsPerPage;
      i < pageContext.currentPage * postsPerPage;
      i++
    ) {
      if (searchResults[i]) {
        tmpResults.push({
          ...searchResults[i],
          featuredImage: searchImages[searchResults[i].slug],
        });
      }
    }
    results = tmpResults;
  }

  return <BlogIndex propsData={props} results={results} />;
};

export default BlogPage;

export const blogListQuery = graphql`
  query blogSearchListQuery($skip: Int!, $limit: Int!, $siteId: Int!) {
    allCraftBlogBlogEntry(
      sort: { fields: postDate, order: DESC }
      limit: $limit
      skip: $skip
      filter: { siteId: { eq: $siteId } }
    ) {
      nodes {
        id
        title
        slug
        author {
          name
          remoteId
        }
        postDate
        dateUpdated
        metaDescription
        featuredImage {
          id
          url
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        featuredImageRetina {
          id
          url
          title
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  aspectRatio: 3.68
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        category {
          title
          id
          slug
        }
      }
    }

    allImages: allCraftBlogBlogEntry(
      filter: { siteId: { eq: $siteId } }
      limit: 1000
    ) {
      nodes {
        slug
        featuredImage {
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        featuredImageRetina {
          ... on Craft_imagesEn_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ... on Craft_imagesRu_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
